import React, {useState, useEffect, useRef, useContext} from 'react'
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import {Column} from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';

import { apiGetProfiles, apiGetCategoryProfiles, apiRemoveCategoryProfiles } from "../../APIService";
import AddCategory from './AddCategory/AddCategory';
import {RoleContext, ROLE_ADMIN} from '../../RoleContext';

import './CategoryProfile.css'

const CategoryProfile = (props) => {
  const [profiles, setProfiles] = useState([])
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [categories, setCategories] = useState([])

  const toast = useRef(null)
  const userRole = useContext(RoleContext);

  useEffect(() => {
    getProfiles()
  }, []);

  useEffect(() => {
    getCategories()
  }, [selectedProfile])

  const getCategories = () => {
    const code = selectedProfile === null ? null : selectedProfile.code
    apiGetCategoryProfiles(code).then(
      data => {
        setCategories(data)
      })
  }

  const getProfiles = () => {
    apiGetProfiles().then(response => {
      const items = response.map((item) => {
        return {name: item.serial, code: item.profile_id}
      })
      items.push({name: 'Общий профиль', code: null}) 
      setProfiles(items)
      if (selectedProfile === null)
        setSelectedProfile(items[0])
    })
  }

  const onProfileChange = (e) => {
    setSelectedProfile(e.value)
  }

  const onAddCategory = () => {
    setShowAddDialog(true)
  }

  const tableHeader = (
    <div className="cat-prof-table-header">
      <Dropdown value={selectedProfile} options={profiles} onChange={onProfileChange} optionLabel="name" placeholder="Выберите профиль" />
      {userRole === ROLE_ADMIN &&
        <Button className='btn-add ml-auto' label='Добавить категорию' onClick={() => (onAddCategory())}/>
      }
    </div>
  )

  const removeCategory = (rowData) => {
    return <Button className='p-button-danger' tooltip='Удалить разрешение'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-minus' 
          onClick={() => onRemoveCategory(rowData)}/>;
  } 

  const  onRemoveCategory = (e) => {
    async function accept() {
      const res = await apiRemoveCategoryProfiles(e.id)
      if (res)
        toast.current.show({ severity: 'success', summary: 'Разрешние удалено', detail: `Категория ${e.name}`, life: 3000 });
      else 
        toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить разрешение.`, life: 3000 });

      getCategories()
    }

    confirmDialog({
      message: (<>Удалить категорию <b>{e.name}</b> из профиля?</>),
      header: 'Удаление разрешения',
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept
    });
  }

  const onRefresh = () => {
    setShowAddDialog(false)
    getCategories()
  }


  return (
    <div>
      {
        showAddDialog && 
        <AddCategory visible={true} onHide={() => setShowAddDialog(false)} onRefresh={onRefresh} refToast={toast} selectedProfile={selectedProfile}/>
      }

      <Toast ref={toast} />
      <ConfirmDialog />
      <DataTable value={categories} scrollable stripedRows resizableColumns columnResizeMode="fit"  
           header={tableHeader} className='cat-prof-table' paginator rows={20}
          dataKey='id' stateStorage="session" stateKey="st-catprof" emptyMessage="Нет данных">
        <Column field='name' header='Категория' sortable></Column> 
        <Column field='comments' header='Описание'></Column> 
        <Column headerStyle={{ width: '4rem'}} body={removeCategory} hidden={userRole !== ROLE_ADMIN}></Column>
      </DataTable>
    </div>
  )
}

export default CategoryProfile