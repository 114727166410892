import React, { useState, useEffect, useRef, useContext } from 'react'
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { apiGetUsers, apiRemoveUser } from "../../APIService";
import AddUser from './AddUser/AddUser';
import { RoleContext, ROLE_ADMIN } from '../../RoleContext';

import './Users.css'

const Users = (props) => {
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [showAddDialog, setShowAddDialog] = useState(false)

  const toast = useRef(null)
  const userRole = useContext(RoleContext);

  useEffect(() => {
    getUsers()
  }, []);

  const getUsers = () => {
    apiGetUsers().then(response => {
      setUsers(response)
    })
  }

  const tableHeader = (
    <div className="users-table-header">
      { userRole === ROLE_ADMIN &&
        <Button className="btn-add" label="Добавить пользователя" onClick={() => onAddUser()}/>
      }
    </div>
  )

  const onAddUser = (e) => {
    setSelectedUser(e === undefined ? null : e)
    setShowAddDialog(true)
  }

  const getAdminRowClass = (rowData) => {
    return rowData.admin_type === 1 ? "user-admin" : ""
  }

  const editUser = (rowData) => {
    return (
      <Button className="btn-edit" tooltip="Редактировать пользователя" 
        tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }} icon="pi pi-pencil" 
        onClick={() => onAddUser(rowData)}
      />
    )
  }

  const removeUser = (rowData) => {
    return (
      <Button className="p-button-danger" tooltip="Удалить пользователя" 
        tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-minus' 
             onClick={() => onRemoveUser(rowData)}/>
    )
  } 

  const onRemoveUser = (e) => {
    async function accept() {
      const res = await apiRemoveUser(e.admin_id)
      if (res) {
          toast.current.show({ severity: 'success', summary: 'Удален', detail: `Пользователь ${e.username}`, life: 3000 });
          getUsers()
      }
      else {
        toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить пользователя.`, life: 3000 });
      }
    }

    confirmDialog({
      message: (<>Удалить пользователя <b>{e.username}</b>?</>),
      header: 'Удаление пользователя',
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept
    }); 
  }

  const refreshUsers = () => {
    setShowAddDialog(false)
    getUsers()
  }

  return (
    <div>
       {
        showAddDialog && 
        <AddUser visible={true} onHide={() => setShowAddDialog(false)} onRefresh={refreshUsers} refToast={toast} selectedUser={selectedUser}/>
      }

      <Toast ref={toast} />
      <ConfirmDialog />
      <DataTable value={users} scrollable stripedRows resizableColumns columnResizeMode="fit"  
           header={tableHeader} className='users-table' paginator rows={20}
          dataKey='admin_id' stateStorage="session" stateKey="st-users" emptyMessage="Нет данных"
          rowClassName={getAdminRowClass}>
        <Column field='username' header='Логин' sortable></Column> 
        <Column field='last_name' header='Фамилия' sortable></Column> 
        <Column field='first_name' header='Имя' sortable></Column> 
        <Column field='email' header='email' sortable></Column> 
        <Column field='comments' header='Описание' sortable></Column> 
        <Column headerStyle={{ width: '4rem'}} body={editUser}></Column>
        <Column headerStyle={{ width: '4rem'}} body={removeUser} hidden={userRole !== ROLE_ADMIN}></Column>
        
      </DataTable>
    </div>
  )
}

export default Users