import React, {useState, useEffect, useRef, useContext} from 'react'
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import {Column} from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { apiGetProfiles, apiRemoveProfile } from "../../APIService";
import AddProfile from './AddProfile/AddProfile';
import {RoleContext, ROLE_ADMIN} from '../../RoleContext';

import './Profiles.css'

const Profiles = (props) => {
  const [profiles, setProfiles] = useState([])
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState(null)
  
  const userRole = useContext(RoleContext);

  const toast = useRef(null)


  useEffect(() => {
    getProfiles()
  }, []);

  const getProfiles = () => {
    apiGetProfiles().then(response => {
      setProfiles(response)
    })
  }

  const tableHeader = (
    <div className="profiles-table-header">
    { userRole === ROLE_ADMIN &&
        <Button className='btn-add' label='Добавить профиль' onClick={() => onAddProfile()}/>
    }
    </div>
  )

  const onAddProfile = (e) => {
    setSelectedProfile(e === undefined ? null : e)
    setShowAddDialog(true)
  }

  
  const editProfile = (rowData) => {
    return <Button className='btn-edit' tooltip='Редактировать профиль' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-pencil' 
              onClick={() => onAddProfile(rowData)}
            />;
  }

  const removeProfile = (rowData) => {
    return <Button className='p-button-danger' tooltip='Удалить профиль'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-minus' 
              onClick={() => onRemoveProfile(rowData)}
            />;
  } 

  const  onRemoveProfile = (e) => {
    async function accept() {
      const res = await apiRemoveProfile(e.profile_id)
      switch(res) {
        case 0:
          toast.current.show({ severity: 'success', summary: 'Удален', detail: `Профиль ${e.serial}`, life: 3000 });
          getProfiles()
          break;
        case 424:
          toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить профиль. Профиль используется.`, life: 3000 });
          break;
        default:
          toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить профиль.`, life: 3000 });
      }
    }

    confirmDialog({
      message: (<>Удалить профиль <b>{e.serial}</b>?</>),
      header: 'Удаление профиля',
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept
    });
  }

  const refreshProfiles = () => {
    setShowAddDialog(false)
    getProfiles()
  }

  return (
    <div>
       {
        showAddDialog && 
        <AddProfile visible={true} onHide={() => setShowAddDialog(false)} onRefresh={refreshProfiles} refToast={toast} selectedProfile={selectedProfile}/>
      }

      <Toast ref={toast} />
      <ConfirmDialog />
      <DataTable value={profiles} scrollable stripedRows resizableColumns columnResizeMode="fit"  
           header={tableHeader} className='profiles-table' paginator rows={20}
          dataKey='profile_id' stateStorage="session" stateKey="st-profiles" emptyMessage="Нет данных">
        <Column field='serial' header='Имя' sortable></Column> 
        <Column field='description' header='Описание'></Column> 
        <Column headerStyle={{ width: '4rem'}} body={editProfile} hidden={userRole !== ROLE_ADMIN}></Column>
        <Column headerStyle={{ width: '4rem'}} body={removeProfile} hidden={userRole !== ROLE_ADMIN}></Column>
        
      </DataTable>
    </div>
  )
}

export default Profiles