import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { apiGetAppAvailableProfiles, apiAddAppProfiles } from "../../../APIService";

import './AddApplication.css'

const AddApplication = (props) => {
  const [applications, setAplications] = useState([])
  const [selectedApp, setSelectedApp] = useState(null)
  const [versions, setVersions] = useState([])
  const [selectedVer, setSelectedVer] = useState(null)
  const [description, setDescription] = useState('')

  useEffect(() => {
    getApplications()
  }, []);

  useEffect(() => {
    if ((selectedApp) && (selectedApp.apks)) {
      setVersions(selectedApp.apks)
      setSelectedVer(selectedApp.apks[0])
    }
  }, [selectedApp]);

  const getApplications = () => {
    apiGetAppAvailableProfiles(props.selectedProfile.code).then(data => {
      setSelectedApp(data[0])
      setAplications(data)
    })
  }

  const toast = useRef(props.refToast)

  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={onHide} className="p-button-text" />
        <Button label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  async function onAdd() {
    if (!selectedVer) {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не выбрано приложение`, life: 3000 })
      return
    }

    const request = {
      profile_id: props.selectedProfile.code,
      app_id: selectedVer.id,
      comments: description,
    }

    const result = await apiAddAppProfiles(JSON.stringify(request))
    if (result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: 'Изменения сохранены', life: 3000 })
    } else {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Изменения не сохранены`, life: 3000 })
    }

    props.onRefresh()
  }

  const onHide = () => {
    props.onHide()
  }

  const onSelectedApp = (e) => {
    setSelectedApp(e.value)
  }

  const onSelectVer = (e) => {
    setSelectedVer(e.value)
  }

  return (
    <div>
      <Dialog className='add-dialog' headerClassName='add-dialog-header' footerClassName='add-dialog-footer' 
        header={'Добавить приложение'} footer={footer} visible={props.visible} onHide={onHide}>
        <div className="add-container">
          <div className="field-add">
            <Dropdown value={selectedApp} options={applications} onChange={onSelectedApp} optionLabel="name" placeholder="Выберите приложение"/>
          </div>
          <div className="field-add">
            <Dropdown value={selectedVer} options={versions} onChange={onSelectVer} optionLabel="name" placeholder="Выберите версию"/>
            {
              selectedVer && <label htmlFor="appVers" style={{color: "#6c757d"}}>Версия: {selectedVer.version}</label>
            }
          </div>
          <div className="field-add">
            <label htmlFor="appDescription">Описание</label>
            <InputTextarea id="appDescription" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>
      </Dialog>
    </div>      
  )
};

export default AddApplication