import React, { useState, useRef, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputTextarea } from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';

import { apiAddUser, apiIsUserExists } from "../../../APIService";
import {RoleContext, ROLE_ADMIN} from '../../../RoleContext';

import './AddUser.css'

const AddUser = (props) => {
  const [userName, setUserName] = useState(props.selectedUser === null ? '' : props.selectedUser.username)
  const [userNameError, setUserNameError] = useState('')
  const [firstName, setFirstName] = useState(props.selectedUser === null ? '' : props.selectedUser.first_name)
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState(props.selectedUser === null ? '' : props.selectedUser.last_name)
  const [lastNameError, setLastNameError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [password2, setPassword2] = useState('')
  const [password2Error, setPassword2Error] = useState('')
  const [email, setEmal] = useState(props.selectedUser === null ? '' : props.selectedUser.email)
  const [emailError, setEmailError] = useState('')
  const [description, setDescroption] = useState(props.selectedUser === null ? '' : props.selectedUser.comments)
  const [isAdmin, setIsAdmin] = useState(props.selectedUser === null ? false : props.selectedUser.admin_type === 1)
  
  const toast = useRef(props.refToast)
  const userRole = useContext(RoleContext);

  const onUserNameChange = (e) => {
    setUserName(e.target.value)
    setUserNameError('')
    validateUserName(e.target.value)
  }

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value)
    setFirstNameError('')
  }

  const onLastNameChange = (e) => {
    setLastName(e.target.value)
    setLastNameError('')
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value)
    setPasswordError('')
    if (password2 !== '') {
      validatePwd(e.target.value, password2)
    }
  }

  const onPassword2Change = (e) => {
    const newPwd = e.target.value
    setPassword2(newPwd)
    validatePwd(password, newPwd)
  }

  const onEmailChange = (e) => {
    setEmal(e.target.value)
    setEmailError('')
  }

  const validatePwd = (pwd1, pwd2) => {
    if ((props.selectedUser == null) && (pwd1 === '')) {
      return true;
    }

    if (pwd1 !== pwd2) {
      setPassword2Error('Пароли не совпадают')
      return false
    }
    setPassword2Error('')
    return true;
  }

  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={onHide} className="p-button-text" />
        <Button label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  async function onAdd() {
    const isValid = await validate()
    if (!isValid)
       return

    let isAdd = (props.selectedUser === null)

    const request = {}

    if (!isAdd) {
      request.admin_id = props.selectedUser.admin_id
    }

    request.userName = userName
    request.firstName = firstName
    request.lastName = lastName
    request.firstName = firstName
    request.password = password
    request.description = description
    request.isAdmin = isAdmin ? 1 : 0
    request.email = email

    const result = await apiAddUser(JSON.stringify(request))
    if (result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: `${isAdd ? 'Пользователь добавлен' : 'Изменения сохранены'}`, life: 3000 })
    } else {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Изменения не сохранены`, life: 3000 })
    }

   props.onRefresh()
  }

  const onHide = () => {
    props.onHide()
  }

  /*async function isUserExists(name) {
    try {
      const userExists = await apiIsUserExists(name);
      return userExists
    } catch (error) {
      return false;
    }
  }    

  const isUserExists2 = () => {
    isUserExists(userName).then((isExist) => {
      if (isExist) {
        setUserNameError('Пользователь с таким логином уже существует');
        return false
      } 
    });

    return true
  }*/

  async function validate() {
    if (!validateUserName(userName)) {
      return false;
    }

    if (props.selectedUser === null) {
      const userExists = await apiIsUserExists(userName);
      if (userExists) {
        setUserNameError('Пользователь с таким логином уже существует');
        return false
      }
    }
    
    if (firstName.length <= 0) {
      setFirstNameError('Заполните имя пользователя')
      return false;
    }
    if (firstName.length > 127) {
      setFirstNameError('Имя пользователя слишком длинное')
      return false;
    }

    if (lastName.length <= 0) {
      setLastNameError('Заполните имя пользователя')
      return false;
    }
    if (lastName.length > 127) {
      setLastNameError('Фамилия пользователя слишком длинная')
      return false;
    }

    if (props.selectedUser === null) {
      if (password.length <= 0) {
        setPasswordError('Заполните пароль')
        return false;
      }
    }
    else if (password.length === 0) {
      return true;
    }
    
    if (!validatePwd(password, password2)) {
      return false;
    }

    if (!validateEmail()) {
      return false;
    }

    return true;
  }

  const validateUserName = (name) => {
    if (name.length <= 0) {
      setUserNameError('Заполните логин')
      return false;
    } else if (name.length > 50) {
      setUserNameError('Логин слишком длинный')
      return false;
    }

    return true;
  }

  const validateEmail = () => {
    if (email.length <=0) {
      setEmailError('Заполните email')
      return false;
    }

    if (email.length > 127) {
      setEmailError('Email слишком длинный')
      return false;
    }


    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!EMAIL_REGEXP.test(email)) {
      setEmailError('Проверьте правильность email')
      return false;
    }

    return true
  }


  return (
    <div>
      <Dialog className='add-dialog' style={{  width: '35rem', height: '50rem'}} headerClassName='add-dialog-header' footerClassName='add-dialog-footer' 
        header={props.selectedUser == null ? 'Добавить пользователя' : 'Редактировать пользователя'} footer={footer} visible={props.visible} onHide={onHide}>
        <div className="add-container">
          <div className="field-add" >
            <label htmlFor="username">Логин</label>
            <InputText id="username" value={userName} onChange={onUserNameChange}/>
            <small id="username-error" className="p-error">{userNameError}</small>
          </div>
          { userRole === ROLE_ADMIN &&
              <div className="field-admin-checkbox">
                <Checkbox inputId="isAdmin" onChange={e => {setIsAdmin(e.checked)}} checked={isAdmin}></Checkbox>
                <label htmlFor="isAdmin">Администратор</label>
              </div>
          }
          <div className='field-add-flex-row'>
            <div className='field-add-flex-column field-add-name' style={{marginRight: '5px'}}>
              <label htmlFor="firstName">Имя</label>
              <InputText id="firstNme" value={firstName} onChange={onFirstNameChange}/>
              <small id="firstName-error" className="p-error">{firstNameError}</small>
            </div>
            <div className='field-add-flex-column field-add-name' style={{marginLeft: '5px'}}>
              <label htmlFor="lastName">Фамилия</label>
              <InputText id="lastName" value={lastName} onChange={onLastNameChange}/>
              <small id="lastName-error" className="p-error">{lastNameError}</small>
            </div>
          </div>
          <div className="field-add">
            <label htmlFor="userPwd">{props.selectedUser == null ? 'Пароль' : 'Новый пароль'}</label>
            <Password id="userPwd" value={password} onChange={onPasswordChange} className='user-pwd' inputClassName='user-pwd-input' toggleMask={true}
             weakLabel='Слабый пароль' mediumLabel='Средный пароль' strongLabel='Сильный пароль' promptLabel='Пароль не может быть пустым'/>
            <small id="userPwd-error" className="p-error">{passwordError}</small>
          </div>
          <div className="field-add">
            <label htmlFor="userPwd2">Повторите пароль</label>
            <Password id="userPwd2" value={password2} onChange={onPassword2Change} className='user-pwd' inputClassName='user-pwd-input' feedback={false} toggleMask={true}/>
            <small id="userPwd2-error" className="p-error">{password2Error}</small>
          </div>
          <div className="field-add">
            <label htmlFor="email">Email</label>
            <InputText type="email" id="email" value={email} onChange={onEmailChange}/>
            <small id="email-error" className="p-error">{emailError}</small>
          </div>
          <div className="field-add">
            <label htmlFor="userDescription">Описание</label>
            <InputTextarea id="userDescription" value={description} onChange={(e) => setDescroption(e.target.value)} />
          </div>
        </div>
      </Dialog>
    </div>      
  )
};

export default AddUser