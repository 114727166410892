import React, {useState, useEffect, useRef, useContext} from 'react'
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import {Column} from 'primereact/column';
import {Image} from 'primereact/image';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { apiGetCategories, apiRemoveCategory } from "../../APIService";
import AddCategory from './AddCategory/AddCategory';
import {RoleContext, ROLE_ADMIN} from '../../RoleContext';

import './Categories.css'

const Categories = (props) => {
  const [categories, setCategories] = useState([])
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [selectedCat, setSelectedCat] = useState(null)

  const toast = useRef(null)

  const userRole = useContext(RoleContext);

  useEffect(() => {
    getCategories()
  }, []);

  const getCategories = () => {
    apiGetCategories().then(response => {
      setCategories(response)
    })
  }

  const tableHeader = (
    <div className="categories-table-header"> 
    { userRole === ROLE_ADMIN &&
        <Button className='btn-add' label='Добавить категорию' onClick={() => onAddCategory()}/>
    }
    </div>
  )

  const onAddCategory = (e) => {
    setSelectedCat(e === undefined ? null : e)
    setShowAddDialog(true)
  }

  const catIcon = (rowData) => {
    return <Image imageClassName='kirmash-icon' src={rowData.icon} ></Image> 
  }

  
  const editCat = (rowData) => {
    return <Button className='btn-edit' tooltip='Редактировать категорию' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-pencil' 
              onClick={() => onAddCategory(rowData)}
            />;
  }


  const removeCat = (rowData) => {
    return <Button className='p-button-danger' tooltip='Удалить категорию'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-minus' 
              onClick={() => onRemoveCat(rowData)}
            />;
  } 

  const  onRemoveCat = (e) => {
    async function accept() {
      const res = await apiRemoveCategory(e.category_id)
      switch(res) {
        case 0:
          toast.current.show({ severity: 'success', summary: 'Удалена', detail: `Категория ${e.name}`, life: 3000 });
          getCategories()
          break;
        case 424:
          toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить категорию. Категория используется.`, life: 3000 });
          break;
        default:
          toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить категорию`, life: 3000 });
      }
    }

    confirmDialog({
      message: (<>Удалить категорию <b>{e.name}</b>?</>),
      header: 'Удаление категории',
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept
    });
  }

  const refreshCategory = () => {
    setShowAddDialog(false)
    getCategories()
  }

  return (
    <div>
       {
        showAddDialog && 
        <AddCategory visible={true} onHide={() => setShowAddDialog(false)} onRefresh={refreshCategory} refToast={toast} selectedCat={selectedCat}/>
      }

      <Toast ref={toast} />
      <ConfirmDialog />
      <DataTable value={categories} scrollable stripedRows resizableColumns columnResizeMode="fit"  
           header={tableHeader} className='categories-table' paginator rows={20}
          dataKey='category_id' stateStorage="session" stateKey="st-categories" emptyMessage="Нет данных">
        <Column body={catIcon}></Column> 
        <Column field='name' header='Категория' sortable></Column> 
        <Column headerStyle={{ width: '4rem'}} body={editCat}  hidden={userRole !== ROLE_ADMIN}></Column>
        <Column headerStyle={{ width: '4rem'}} body={removeCat} hidden={userRole !== ROLE_ADMIN}></Column>
        
      </DataTable>
    </div>
  )
}

export default Categories