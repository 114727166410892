import React, {useState, useEffect, memo} from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { useNavigate, useLocation } from 'react-router-dom'


const KTabMenu = (props) => {
  const [activeTab, setActiveTab] = useState(0)
    
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const loc = location
     
    if (loc.pathname === "/applications") {
      setActiveTab(0)
    }
    else if (loc.pathname === "/settings") {
      setActiveTab(1)
    }
    else if (loc.pathname === "/statistics") {
      setActiveTab(2)
    }
    else if (loc.pathname === "/users") {
      setActiveTab(3)
    }
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 0:
        navigate("/applications")
        break
      case 1:
        navigate("/settings")
        break
      case 2:
        navigate("/statistics")
        break
      case 3:
        navigate("/users")
        break
      case 4:
        localStorage.removeItem("login")
        localStorage.removeItem("user_info")
        setActiveTab(null)
        navigate("/login" )
        break
      default:
        break
    }
  }, [activeTab]); 
    

  const items = [
    {label: 'Приложения', icon: 'pi pi-fw pi-android'},
    {label: 'Настройки', icon: 'pi pi-fw pi-cog'},
    {label: 'Статистика', icon: 'pi pi-fw pi-chart-pie'},
    {label: 'Пользователи', icon: 'pi pi-fw pi-user'},
    {label: 'Выход', icon: 'pi pi-fw pi-chevron-circle-right'},
  ];

  const onTabChange = (e) => {
    setActiveTab(e.index)
  }

  return (
    <div>
      <TabMenu model={items} activeIndex={activeTab} onTabChange={onTabChange} className="k-tabmenu" />
    </div>
  )
} 

export default memo(KTabMenu)