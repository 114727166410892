import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {Base64} from 'js-base64';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

import { apiLogin } from './APIService'

import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css';

const Login = (props) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [nameError, setNameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const toast = useRef(null);

  const navigate = useNavigate()

  const onButtonClick = () => {
    setNameError('')
    setPasswordError('')
  
    if ('' === username) {
      setNameError('Введите имя пользователя')
      return
    }
  
    if ('' === password) {
      setPasswordError('Введите пароль')
      return
    }
  
    logIn();
  }

  const logIn = () => {
    const errorLogin = () => {
      toast.current.show({severity:'error', summary: 'Ошибка', detail:'Неправильное имя пользователя или пароль', life: 3000})
    }

    const base64encodedData = Base64.encode(username + ":" + password);
   
    apiLogin(base64encodedData).then(response => {
      if (true === response.success) {
        localStorage.setItem("user_info", JSON.stringify({token: response.token}))
        props.setLoggedIn(true)
        props.setUsername(username)
        props.setRole(response.role)
        navigate("/applications")
      } 
      else {
        errorLogin();
      }
    })
    .catch(error => {
        errorLogin();
    });
  }

  return (
    <div className={'loginContainer'}>
   
      <Toast ref={toast} />
      <div className={'inputContainer'}>
        <InputText inputClassName="k-input-login" placeholder='Имя пользователя' value={username} onChange={(e) => setUsername(e.target.value)} />
        <label className="errorLabel">{nameError}</label>
      </div>
      <div className={'inputContainer'}>
        <Password placeholder='Пароль' value={password} onChange={(e) => setPassword(e.target.value)} feedback={false} toggleMask
          className='k-password' inputClassName='k-input-password'
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
    
      <div className={'inputContainer'}>
        <Button label='Войти' onClick={onButtonClick} />
      </div>
    </div>
   
  )
}

export default Login