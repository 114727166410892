import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import {apiAddVersion} from '../../../APIService';

import '../AddApplication.css'

const AddAVersion = (props) => {
  const [apkName, setApkName] = useState(props.selectedVersion == null ? '' : props.selectedVersion.name)
  const [apk, setApk] = useState(null)
  const [apkError, setApkError] = useState('')

  const apkInputRef = useRef(null);
  const toast = useRef(props.refToast)

  async function onAdd() {
    if (!validate()) {
      return;
    }

    let isAdd = (props.selectedVersion == null)

    const formData = new FormData();
   
    if (isAdd) {
      formData.append('app_id', props.selectedApp.app_id);
    }

    formData.append('apk_name', apkName);
      
    if (!isAdd) {
      formData.append('id', props.selectedVersion.id);
    }

    formData.append("apk", apk);
    
    const result = await apiAddVersion(formData)
    if (result.result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: `${isAdd ? 'Версия добавлена' : 'Изменения сохранены'}`, life: 3000 })
    }
    else {
      const errorText = result?.errorText ?? 'Изменения не сохранены' 
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: errorText, life: 3000 })
    }

    props.onRefresh()
  }

  const apkBtnClick = () => {
    apkInputRef.current.click();
  }

  const onApkChange = (e) => {
    const selectedFile = e.target.files[0];
      if (selectedFile !== undefined) {
        setApk(selectedFile)
        setApkName(selectedFile.name)
      }
  }

  const validate = () => {

    if (apkName === '') {
      setApkError('Добавьте apk-файл')
      return false
    }

    return true;
  }


  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={props.onHide} className="p-button-text" />
        <Button label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  return (
    <div>
      <Dialog visible={props.visible} onHide={props.onHide} 
        className='add-version-dialog' headerClassName='add-dialog-header' footer={footer} footerClassName='add-dialog-footer'
        header={props.selectedVersion == null ? 'Добавить версию' : 'Редактировать версию'}> 
        <div className="add-container">
          <span className="field-add">
            <span className='add-apk'>
              <Button onClick={apkBtnClick} icon = {`pi pi-fw ${apkName === '' ? 'pi-plus' : 'pi-pencil'}`} label={apkName === '' ? 'Добавить apk-файл' : `Заменить ${apkName}`}></Button>
              <input type="file" ref={apkInputRef} accept=".apk" style={{ display: 'none' }} onChange={onApkChange}/>
            </span>              
            <small id="apk-name-error" className="p-error">{apkError}</small>
          </span>
        </div>
      
      </Dialog>
    </div>      
  )
}

export default AddAVersion