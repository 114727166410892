import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { apiGetCategoryAvailableProfiles, apiAddCategoryProfiles } from "../../../APIService";

import './AddCategory.css'

const AddCategory = (props) => {
  const [categories, setCategories] = useState([])
  const [selectedCat, setSelectedCat] = useState(null)
  const [description, setDescription] = useState('')

  useEffect(() => {
    getCategories()
  }, []);

  const getCategories = () => {
    apiGetCategoryAvailableProfiles(props.selectedProfile.code).then(data => {
      setSelectedCat(data[0])
      setCategories(data)
    })
  }

  const toast = useRef(props.refToast)

  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={onHide} className="p-button-text" />
        <Button label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  async function onAdd() {
    if (!selectedCat) {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Категория не выбрана`, life: 3000 })
      return
    }

    const request = {
      profile_id: props.selectedProfile.code,
      category_id: selectedCat.category_id,
      comments: description,
    }

    const result = await apiAddCategoryProfiles(JSON.stringify(request))
    if (result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: 'Изменения сохранены', life: 3000 })
    } else {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Изменения не сохранены`, life: 3000 })
    }

    props.onRefresh()
  }

  const onHide = () => {
    props.onHide()
  }

  const onSelectedCat = (e) => {
    setSelectedCat(e.value)
  }

  return (
    <div>
      <Dialog className='add-dialog' headerClassName='add-dialog-header' footerClassName='add-dialog-footer' 
        header={'Добавить категорию'} footer={footer} visible={props.visible} onHide={onHide}>
        <div className="add-container">
          <div className="field-add">
            <Dropdown value={selectedCat} options={categories} onChange={onSelectedCat} optionLabel="name" placeholder="Выберите категорию"/>
          </div>
          <div className="field-add">
            <label htmlFor="catDescription">Описание</label>
            <InputTextarea id="catDescription" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>
      </Dialog>
    </div>      
  )
};

export default AddCategory