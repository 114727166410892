import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { useState } from 'react'
import { jwtDecode } from 'jwt-decode' 

import Login from './login'
import Applications from './Applications/Applications'
import SettingsMenu from './SettingsMenu/SettingsMenu'
import Statistics from './Statistics/Statistics'
import Users from './SettingsMenu/Users/Users'
import KTabMenu from './KTabMenu'
import { RoleContext } from './RoleContext';
import usePersistState from './usePersistState'
import {getValueFromToken} from './utils/utils'

import './App.css'

function App() {
  function checkJwt() {
    const userToken = localStorage.getItem("user_info");
    const parsedToken = userToken ? JSON.parse(userToken) : null;
    const expirationTime = parsedToken ? jwtDecode(parsedToken.token).exp : null;

    if (expirationTime * 1000 < Date.now()) {
      localStorage.removeItem("login")
      return false;
    }
   
    return true;
  }

  const [loggedIn, setLoggedIn] = usePersistState('login', checkJwt())
  const [username, setUsername] = useState(getValueFromToken('name', ''))
  const [role, setRole] = useState(getValueFromToken('type', 0))

  const PrivateRoute = () => {
    return loggedIn ? <Outlet /> : <Navigate to="/login" />;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setUsername={setUsername} setRole={setRole}/>} />
        </Routes>

        {
          loggedIn ? <KTabMenu></KTabMenu> : <></>
        }

        <RoleContext.Provider value={role}>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/applications" element={<Applications setUsername={setUsername} loggedIn={loggedIn}/>} />
              <Route path="/settings" element={<SettingsMenu setUsername={setUsername} loggedIn={loggedIn}/>} />
              <Route path="/statistics" element={<Statistics setUsername={setUsername} loggedIn={loggedIn}/>} />
              <Route path="/users" element={<Users setUsername={setUsername} loggedIn={loggedIn}/>} />
            </Route>  
          </Routes>
        </RoleContext.Provider>
      </BrowserRouter>
    </div>
  )
}

export default App