import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { apiAddCategory } from "../../../APIService";

import './AddCategory.css'

const AddCategory = (props) => {
  const [name, setName] = useState(props.selectedCat == null ? '' : props.selectedCat.name)
  const [icon, setIcon] = useState(null)
  const [iconUrl, setIconUrl] = useState(props.selectedCat == null ? '' : props.selectedCat.icon)
  const [nameError, setNameError] = useState('')
  
  const toast = useRef(props.refToast)
  const imageInputRef = useRef(null);

  const onNameChange = (e) => {
    setName(e.target.value)
    setNameError('')
  }

  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={onHide} className="p-button-text" />
        <Button label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  async function onAdd() {
    if (!validate()) {
      return;
    }

    let isAdd = (props.selectedCat == null)

    const formData = new FormData();
    if (icon != null) {
      formData.append("image", icon);
    }
      
    if (!isAdd) {
      formData.append('category_id', props.selectedCat.category_id);
    }

    formData.append('name', name);
    const result = await apiAddCategory(formData)
    if (result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: `${isAdd ? 'Категория добавлена' : 'Изменения сохранены'}`, life: 3000 })
    } else {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Изменения не сохранены`, life: 3000 })
    }

   props.onRefresh()
  }


  const iconBtnClick = () => {
    imageInputRef.current.click();
  }

  const onIconChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile !== undefined) {
      setIcon(selectedFile)
      setIconUrl(URL.createObjectURL(selectedFile))
    }
  };


  const onHide = () => {
    props.onHide()
  }

  const validate = () => {
    if (name.length <= 0) {
      setNameError('Заполните название категории')
      return false;
    } else if (name.length > 50) {
      setNameError('Название категории слишком длинное')
      return false;
    }
    
    return true;
  }


  return (
    <div>
      <Dialog className='add-dialog' headerClassName='add-dialog-header' footerClassName='add-dialog-footer' 
        header={props.selectedCat == null ? 'Добавить категорию' : 'Редактировать категорию'} footer={footer} visible={props.visible} onHide={onHide}>
        <div className="add-container">
          <div className="field-add">
            <label htmlFor="catName">Название катеогрии</label>
            <InputText id="catName" value={name} onChange={onNameChange} />
            <small id="catName-error" className="p-error">{nameError}</small>
          </div>
          <span className="field-add">
            <label htmlFor="appDev">Иконка</label>
            <span className='add-app-icon'>
              <Image imageClassName='add-app-image' src={iconUrl}></Image>
              <Button onClick={iconBtnClick} icon={`pi pi-fw ${iconUrl === '' ? 'pi-plus' : 'pi-pencil'}`} label={iconUrl === '' ? 'Добавить' : 'Изменить'}></Button>
              <input type="file" ref={imageInputRef} accept="image/*" style={{ display: 'none' }} onChange={onIconChange}/>
            </span>              
            <small id="appDev-error" className="p-error"></small>
          </span>
        </div>
      </Dialog>
    </div>      
  )
};

export default AddCategory