import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputTextarea } from 'primereact/inputtextarea';

import { apiAddProfile } from "../../../APIService";

import './AddProfile.css'

const AddProfile = (props) => {
  const [serial, setSerial] = useState(props.selectedProfile == null ? '' : props.selectedProfile.serial)
  const [serialError, setSerialError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [password2, setPassword2] = useState('')
  const [password2Error, setPassword2Error] = useState('')
  const [description, setDescroption] = useState(props.selectedProfile == null ? '' : props.selectedProfile.description)
  
  const toast = useRef(props.refToast)

  const onSerialChange = (e) => {
    setSerial(e.target.value)
    setSerialError('')
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value)
    setPasswordError('')
  }

  const onPassword2Change = (e) => {
    const newPwd = e.target.value
    setPassword2(newPwd)
    validatePwd(password, newPwd)
  }

  const validatePwd = (pwd1, pwd2) => {
    if ((props.selectedProfile == null) && (pwd1 === '')) {
      return true;
    }

    if (pwd1 !== pwd2) {
      setPassword2Error('Пароли не совпадают')
      return false
    }
    setPassword2Error('')
    return true;
  }

  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={onHide} className="p-button-text" />
        <Button label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  async function onAdd() {
    if (!validate()) {
      return;
    }

    let isAdd = (props.selectedProfile === null)

    const request = {}

    if (!isAdd) {
      request.profile_id = props.selectedProfile.profile_id
    }

    request.serial = serial
    request.password = password
    request.description = description

    const result = await apiAddProfile(JSON.stringify(request))
    if (result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: `${isAdd ? 'Категория добавлена' : 'Изменения сохранены'}`, life: 3000 })
    } else {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Изменения не сохранены`, life: 3000 })
    }

   props.onRefresh()
  }

  const onHide = () => {
    props.onHide()
  }

  const validate = () => {
    if (serial.length <= 0) {
      setSerialError('Заполните название профиля')
      return false;
    } else if (serial.length > 50) {
      setSerialError('Название профиля слишком длинное')
      return false;
    }

    if (props.selectedProfile === null) {
      if (password.length <= 0) {
        setPasswordError('Заполните пароль')
        return false;
      }
    }
    else if (password.length === 0) {
      return true;
    }
    
    if (!validatePwd(password, password2)) {
      return false;
    }

    return true;
  }


  return (
    <div>
      <Dialog className='add-dialog' headerClassName='add-dialog-header' footerClassName='add-dialog-footer' 
        header={props.selectedProfile == null ? 'Добавить профиль' : 'Редактировать профиль'} footer={footer} visible={props.visible} onHide={onHide}>
        <div className="add-container">
          <div className="field-add">
            <label htmlFor="profileSerial">Имя профиля</label>
            <InputText id="profileSerial" value={serial} onChange={onSerialChange}/>
            <small id="profileSerial-error" className="p-error">{serialError}</small>
          </div>
          <div className="field-add">
            <label htmlFor="profilePwd">{props.selectedProfile == null ? 'Пароль' : 'Новый пароль'}</label>
            <Password id="profilePwd" value={password} onChange={onPasswordChange} className='profile-pwd' inputClassName='profile-pwd-input' toggleMask={true}
             weakLabel='Слабый пароль' mediumLabel='Средный пароль' strongLabel='Сильный пароль' promptLabel='Пароль не может быть пустым'/>
            <small id="profilePwd-error" className="p-error">{passwordError}</small>
          </div>
          <div className="field-add">
            <label htmlFor="profilePwd2">Повторите пароль</label>
            <Password id="profilePwd2" value={password2} onChange={onPassword2Change} className='profile-pwd' inputClassName='profile-pwd-input' feedback={false} toggleMask={true}/>
            <small id="profilePwd2-error" className="p-error">{password2Error}</small>
          </div>
          <div className="field-add">
            <label htmlFor="profileDescription">Описание</label>
            <InputTextarea id="profileDescription" value={description} onChange={(e) => setDescroption(e.target.value)} />
          </div>
        </div>
      </Dialog>
    </div>      
  )
};

export default AddProfile