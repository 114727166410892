import React, { useState, useEffect, useRef, useContext } from 'react' 
import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Button } from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Image} from 'primereact/image';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AddApplication from './AddApplication/AddApplication';
import AddAVersion from './AddApplication/AddVersion/AddVersion';
import {apiGetApplications, apiRemoveApp, apiRemoveVersion, apiGetCategories} from '../APIService';
import {RoleContext, ROLE_ADMIN} from '../RoleContext';

import './Applications.css'

const Applications = (props) => {
  const [applications, setAplications] = useState([])
  const [expandedRows, setExpandedRows] = useState(null)
  const [showAddApp, setShowAddApp] = useState(false)
  const [selectedApp, setSelectedApp] = useState(null)
  const [showAddVersion, setShowAddVersion] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [categories, setCategories] = useState([])
  const [filters, setFilters] = useState({
    category: { value: null,matchMode: FilterMatchMode.EQUALS },
  });

  const userRole = useContext(RoleContext);

  const toast = useRef(null)

  useEffect(() => {
    getApplications()
    getCategories()

    const savedGlobalFilter = sessionStorage.getItem('globalFilter');
    if (savedGlobalFilter) {
      setFilters(savedGlobalFilter);
    }
  }, []); 

  const getApplications = () => {
    apiGetApplications().then(data => setAplications(data))
  }

  const getCategories = () => {
    const savedCategory = sessionStorage.getItem('filterCategory');
    if (savedCategory) {
      setFilters({
        category: { value: savedCategory,matchMode: FilterMatchMode.EQUALS },
      })
    }

    apiGetCategories().then(response => {
      const items = response.map((item) => {
        return {label: item.name, value: item.name}
      })
      setCategories(items)

    })
  }

  const removeVersion = (rowData, other) => {
    return <Button className='p-button-danger' tooltip='Удалить версию'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-minus' 
              onClick={() => onRemoveVersion(rowData, other.props.parentapp)}
            />;
  }

  const onRemoveVersion = (e, parentApp) => {
    async function accept() {
    
      const res = await apiRemoveVersion(e.id)
      if (res) {
        toast.current.show({ severity: 'success', summary: 'Удалена', detail: `Версия ${e.name}`, life: 3000 });
        getApplications()
        console.log('expandedRows', expandedRows)
      }
      else {
        toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить версию`, life: 3000 });   
      }
    }

    confirmDialog({
      message: (<>Удалить версию <b>{e.version}</b> приложения <b>{parentApp}</b>?</>),
      header: 'Удаление версии',
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept
    });

  }
    
  const addVersion = (rowData) => {
    return <Button className='btn-add' tooltip='Добавить версию'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-plus' 
              onClick={() => onAddVersion(rowData)}
            />;
  }

  const onAddVersion = (e) => {
    setSelectedApp(e)
    setSelectedVersion(null)
    setShowAddVersion(true)
  }

  const editApp = (rowData) => {
    return <Button className='btn-edit' tooltip='Редактировать приложение'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-pencil' 
              onClick={() => onEditApp(rowData)}
            />;
  }

  const onEditApp = (e) => {
    setSelectedApp(e)
    setShowAddApp(true)
  }

  const removeApp = (rowData) => {
    return <Button className='p-button-danger' tooltip='Удалить приложение'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-minus' 
              onClick={() => onRemoveApp(rowData)}
            />;
  } 

  const  onRemoveApp = (e) => {
    async function accept() {
    
      const res = await apiRemoveApp(e.app_id)
      if (res) {
        toast.current.show({ severity: 'success', summary: 'Удалено', detail: `Приложение ${e.name}`, life: 3000 });
        getApplications()
      }
      else {
        toast.current.show({ severity: 'error', summary: 'Ошибка', detail: `Не удалось удалить приложение`, life: 3000 });   
      }
    }

    confirmDialog({
      message: (<>Удалить приложение <b>{e.name}</b> и все его версии?</>),
      header: 'Удаление приложения',
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept
    });
  }

  const editVersion = (rowData) => {
    return <Button className='btn-edit' tooltip='Редактировать версию'  tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} icon='pi pi-pencil' 
              onClick={() => onEditVersion(rowData)}
            />;
  }

  const onEditVersion = (e) => {
    setSelectedVersion(e)
    setShowAddVersion(true)
  }

  const appIcon = (rowData) => {
    return <Image imageClassName='kirmash-icon' src={rowData.icon} ></Image> 
  }

  const fieldSize = (rowData) => {
    return rowData.size
  }
  
  const fieldDate = (rowData) => {
    const date = new Date(rowData.time_release)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return`${day}.${month}.${year}`
  }

  const rowAppVersion = (data) => {
    console.log('rowAppVersion: ', data)
    if (data?.apks?.length <= 0) {
      let updateExpandedRows = {...expandedRows}
      if (updateExpandedRows.hasOwnProperty(data.app_id)) {
        delete updateExpandedRows[data.app_id]
        setExpandedRows(updateExpandedRows)
        console.log('expandedRows', expandedRows)
      }
      return <></>
    } 
    return (
      <div className='version-table'>
        <DataTable value={data?.apks} resizableColumns columnResizeMode="fit" className='version-app-table' parentapp={data.name} emptyMessage="Нет данных">
          <Column field='package_name' header='Имя пакета' sortable></Column>
          <Column field='version' header='Версия' sortable></Column>
          <Column field='version_code' header='Код версии' sortable></Column>
          <Column field='size' body={fieldSize} header='Размер(байт)' sortable></Column>
          <Column field='name' header='Имя apk' sortable></Column>
          <Column field='time_release' body={fieldDate} header='Дата добавления' sortable></Column> 
          <Column headerStyle={{ width: '4rem'}} body={editVersion} hidden={userRole !== ROLE_ADMIN}></Column>
          <Column headerStyle={{ width: '4rem'}}  body={removeVersion} hidden={userRole !== ROLE_ADMIN}></Column>
        </DataTable>
      </div>
    )
  }

  const allowExpansion = (rowData) => {
    return rowData.apks.length > 0;
  }

  const tableHeader = (
    <div className="app-table-header">
      {userRole === ROLE_ADMIN &&
        <Button className='btn-add' label='Добавить приложение' onClick={() => onAddApplication()}/>
      }
    </div>
  )

  const onAddApplication = () => {
    setSelectedApp(null)
    setShowAddApp(true)
  }

  const refreshApplications = () => {
    setShowAddApp(false)
    getApplications()
  }

  const refreshVersion = () => {
    setShowAddVersion(false)
    getApplications()
  }

  const categoryFilter = (options) => {
    return (
      <Dropdown value={options.value} options={categories}
        onChange={(e) => { 
          console.log('filter change');
          options.filterApplyCallback(e.value)
          sessionStorage.setItem('filterCategory', e.value ?? '');
        }} showClear placeholder="Выберите категорию" />
    )  
  }


  return (
    <div>
      {
        showAddApp && 
        <AddApplication visible={true} onHide={() => setShowAddApp(false)} onRefresh={refreshApplications} refToast={toast} selectedApp={selectedApp}/>
      }
      {
         showAddVersion &&  
         <AddAVersion visible={true} onHide={() => setShowAddVersion(false)} onRefresh={refreshVersion} refToast={toast} selectedApp={selectedApp} selectedVersion={selectedVersion}></AddAVersion>
      }
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className='app-table-container'>
        <DataTable value={applications} scrollable stripedRows resizableColumns columnResizeMode="fit" rowExpansionTemplate={rowAppVersion}  
          onRowToggle={(e) => setExpandedRows(e.data)} expandedRows={expandedRows} header={tableHeader} className='app-table' 
          paginator rows={20} rowsPerPageOptions={[20,40,100]} filters={filters}  onFilter={(e) => {
              sessionStorage.setItem('filterCategory', '');
              setFilters(e.filters)}}
          dataKey='app_id' stateStorage="session" stateKey="st-applications" emptyMessage="Нет данных" 
          globalFilterFields={['category']} filterDisplay="row">
          <Column expander={allowExpansion} style={{ width: '3em' }} />
          <Column body={appIcon} headerStyle={{ width: '4rem'}}></Column> 
          <Column field='name' header='Название приложения' sortable></Column> 
          <Column field='developer' header='Разработчик' sortable></Column> 
          <Column field='category' header='Категория' filter filterElement={categoryFilter} showClearButton={true} showFilterMenu={false} sortable></Column> 
          <Column field='desc' header='Описание' className='app-desc'></Column> 
          <Column headerStyle={{ width: '4rem'}} body={editApp} hidden={userRole !== ROLE_ADMIN}></Column>
          <Column headerStyle={{ width: '4rem'}} body={addVersion} hidden={userRole !== ROLE_ADMIN}></Column>
          <Column headerStyle={{ width: '4rem'}} body={removeApp} hidden={userRole !== ROLE_ADMIN}></Column>
        </DataTable>
      </div>
    </div>
  )
}

export default Applications