import { jwtDecode } from 'jwt-decode' 

function getToken() {
  const userToken = localStorage.getItem("user_info");
  if (!userToken)
    return null
  const parsedToken = JSON.parse(userToken)
  return parsedToken.token
}

function getValueFromToken(value, defaultValue) {
  const token = getToken()
  if (!token) {
    return defaultValue
  }

  const expirationTime = jwtDecode(token).exp;

  if (expirationTime * 1000 < Date.now()) {
    return defaultValue
  }

  const res = jwtDecode(token)[value]
  return res
}

export {getToken, getValueFromToken}; 