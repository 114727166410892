import React, { useState, useEffect } from 'react' 
import { DataTable  } from 'primereact/datatable'
import {Column} from 'primereact/column'
import { Calendar } from 'primereact/calendar';
import { locale, addLocale } from 'primereact/api';

import {apiGetStatistics} from '../APIService';

import './Statistics.css'

const Statistics = (props) => {
  const [statistics, setStatistics] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(false)

  const getLazyParam = () => {
    let defaultParam = {
      first: 0,
      rows: 30,
      page: 0,
      sortField: 'date_update',
      sortOrder: -1,
      filters: {
        'serial_number': { value: '', matchMode: 'contains' },
        'name': { value: '', matchMode: 'contains' },
        'version_name': { value: '', matchMode: 'contains' },
        'app_cur_ver': { value: '', matchMode: 'contains' },
        'date_install': { value: null, strValue: '' },
        'date_update': { value: null, strValue: '' },
      }
    }

    const data = window.sessionStorage.getItem('st-statistics')
    if (data != null && data !== undefined) {
      try {
        defaultParam = JSON.parse(data)
        defaultParam.filters.date_install.value = defaultParam.filters.date_install.value ? new Date(defaultParam.filters.date_install.value)  : null
        defaultParam.filters.date_update.value = defaultParam.filters.date_update.value ? new Date(defaultParam.filters.date_update.value) : null
      }
      catch (e) {}
    }

    return defaultParam
  }

  const [lazyParams, setLazyParams] = useState(getLazyParam());

  useEffect(() => {
    getStatistics()
  }, []); 

  useEffect(() => {
    getStatistics()
  }, [lazyParams]); 

  const getStatistics = () => {
    setLoading(true);
    apiGetStatistics(lazyParams).then(data => {
      setTotalRecords(data.total);
      setStatistics(data.data)
     setLoading(false)
    })
  }

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0')
    const minute = String(date.getMinutes()).padStart(2, '0')
    const second = String(date.getSeconds()).padStart(2, '0')

    return`${day}.${month}.${year} ${hour}:${minute}:${second}`
  }

  const formatDate = (dateTime) => {
    const date = new Date(dateTime)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear();

    return`${day}.${month}.${year}`
  }

  const fieldDateInstall = (rowData) => {
    return formatDateTime(rowData.date_install)
  }

  const fieldDateUpdate = (rowData) => {
    return formatDateTime(rowData.date_update)
  }

  const tableHeader = (
    <div className="stat-table-header"/>
  )

  const onSort = (e) => {
    setLazyParams(e);
  }

  const onPage = (e) => {
    setLazyParams(e);
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }

  const dateFilterInstall = (options) => {
    const onInstallFilterChange = (eValue, event) => {
      let value = { ...lazyParams}  
      value.filters.date_install.value = eValue 
      if (eValue === null ||eValue === undefined) {
        value.filters.date_install.strValue = ''
      }
      else {
        value.filters.date_install.strValue = formatDate(eValue)
      }

      options?.filterCallback(eValue)
      setLazyParams(value)
    }

    return <Calendar value={lazyParams.filters.date_install.value} onChange={(e) => onInstallFilterChange(e.value, e)} firstDayOfWeek={1} locale="ru" dateFormat="dd.mm.yy" placeholder="ДД.ММ.ГГГГ" mask="99.99.9999" />
  }

  const dateFilterUpdate = (options) => {
    const onUpdateFilterChange = (eValue) => {
      let value = { ...lazyParams}
      value.filters.date_update.value = eValue;
      value.filters.date_update.value = eValue 
      if (eValue === null ||eValue === undefined) {
        value.filters.date_update.strValue = ''
      }
      else {
        value.filters.date_update.strValue = formatDate(eValue)
      }

      options?.filterCallback(eValue)
      setLazyParams(value);
    }

    return <Calendar value={lazyParams.filters.date_update.value} onChange={(e) => onUpdateFilterChange(e.value, e.target.value)} firstDayOfWeek={1} locale="ru" dateFormat="dd.mm.yy" placeholder="ДД.ММ.ГГГГ" mask="99.99.9999" />
  }

  const onCustomSaveState = (state) => {
    window.sessionStorage.setItem('st-statistics', JSON.stringify(lazyParams));
  }

  addLocale('ru', {
    firstDayOfWeek: 1,
    dayNames: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье '],
    dayNamesShort: ['Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.', 'Вс.'],
    dayNamesMin: ['Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.', 'Вс.'],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    today: 'Сегодня',
    clear: 'Очистить',
    noFilter: 'Очистить',
    dateIs: 'Дата равна',
    dateIsNot: 'Дата не равна',
    dateBefore: 'Дата до',
    dateAfter: 'Дата после',
  });

  locale('ru');
 
  return (
    <div>
      <div className="stat-table-container">
      <DataTable value={statistics} paginator rows={lazyParams.rows} rowsPerPageOptions={[30, 50, 70]} lazy scrollable 
        stripedRows resizableColumns columnResizeMode="fit" className="stat-table" first={lazyParams.first} loading={loading}
        stateStorage="custom" stateKey="st-statistics" emptyMessage="Нет данных" header={tableHeader} filterDisplay="row" 
        totalRecords={totalRecords} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onSort={onSort} 
        onPage={onPage} onFilter={onFilter} filters={lazyParams?.filters} filterLocale="ru" customSaveState={onCustomSaveState}>
          <Column field="serial_number" header="Терминал" sortable filter showFilterMenu={false}></Column> 
          <Column field="name" header="Приложение" sortable filter showFilterMenu={false}></Column> 
          <Column field="version_name" header="Версия" sortable filter showFilterMenu={false}></Column> 
          <Column field="app_cur_ver" header="Код версии" sortable filter showFilterMenu={false}></Column> 
          <Column field="date_install" dataType="date" body={fieldDateInstall} header="Дата установки" sortable filter 
            filterElement={dateFilterInstall} showFilterMenu={false} > 
          </Column> 
          <Column field="date_update" dataType="date" body={fieldDateUpdate} header="Дата обновления" sortable filter 
            filterElement={dateFilterUpdate} showFilterMenu={false} >
          </Column> 
        </DataTable>
      </div>
    </div>
  )
}

export default Statistics