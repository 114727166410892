import React, { useState, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { Menu } from 'primereact/menu'
import { FaMobileAlt } from "react-icons/fa"

import Categories from './Categories/Categories'
import Profiles  from './Profiles/Profiles'
import Applications from './Applications/Applications'
import CategoryProfile from './CategotyProfile/CategoryProfile'
import usePersistState from '../usePersistState'

import './SettingsMenu.css'

const SettingsMenu = (props) => {
  const storageName = 'settingsItem'

  const [activeItem, setActiveItem] = usePersistState(storageName, 0)

  const toast = useRef(null)

  const mobileIcon = (
    <span className="sett-icon">
      <FaMobileAlt />
    </span>
  )

  const items = [
    {
      label:  '',
      className: 'sett-header-rem-empty',
      items: [
        {label: 'Категории', icon: 'pi pi-fw pi-table', className: activeItem === 0 ? 'item-active' : 'item-inactive', command: () =>{setActiveItem(0)} },
        {label: 'Профили', icon: mobileIcon,  className: activeItem === 1 ? 'item-active' : 'item-inactive', command: () =>{setActiveItem(1)}},
      ]
    },
    {
      label:  'Разрешения',
      className: 'sett-header-rem',
      items: [
        {label: 'Приложения', icon: 'pi pi-fw pi-android', className: activeItem === 2 ? 'item-active' : 'item-inactive', command: () =>{setActiveItem(2)} },
        {label: 'Категории', icon: 'pi pi-fw pi-table', className: activeItem === 3 ? 'item-active' : 'item-inactive', command: () =>{setActiveItem(3)} },
      ]
    },
  ]

  const getContent = () => {
    switch(activeItem) {
      case 0: return <Categories/>
      case 1: return <Profiles/>  
      case 2: return <Applications/>
      case 3: return <CategoryProfile/>
      default:
        return <div/>
    }
  }

  return (
    <div>
      <Toast ref={toast} />
        <div className="settings-panel">
          <aside className="settings-sidebar">
            <Menu className="settings-menu" model={items} />
          </aside>
          <div className="settings-content">
            { getContent()}
          </div>
        </div>
    </div>
  )
}

export default SettingsMenu